import ProjectComponent from "./project_component";
import React, { forwardRef } from "react";
import "./project.scss";

var projects_data = [
    {
        title: "GAIL Compressor Analysis",
        title_sub_heading: "Data Analysis, Visualization",
        imagePath: require("../../images/gail.jpg"),
        description:
            "For KPMG, analysis of compressors used at GAIL (Gas AUthority of India Ltd.) was done in order to find innefficiencies in the way they get handled.",
    },
    {
        title: "Realtime Parking System",
        title_sub_heading: "Computer Vision, App Development",
        imagePath: require("../../images/parking.jpg"),
        description:
            "Problem posted by Ministry of Housing & Urban Affairs, the team created ML models and an application to utilize available parking space to max extent.",
    },
    {
        title: "Prompt To Action LLM",
        title_sub_heading: "Prompt Engineering, LLM",
        imagePath: require("../../images/ai.jpg"),
        description:
            "Implemented a state-of-the-art LLM model to autonomously perform actions on the devices based on the prompt.",
    },
];
const Projects = forwardRef((props, ref) => {
    return (
        <div
            ref={ref}
            className="project_section"
        >
            <h1>Projects</h1>
            {projects_data.map((element, index) => (
                <ProjectComponent
                    key={index}
                    imagePath={element.imagePath}
                    title={element.title}
                    title_sub_heading={element.title_sub_heading}
                    description={element.description}
                    link={element.link}
                    rank={element.rank}
                    buttonText={element.buttonText}
                />
            ))}
        </div>
    );
});

export default Projects;