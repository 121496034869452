import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ExpandableMenu from "../expandable_menu/expandable_menu";
import "./appBar.scss";

export default function MainAppBar(props) {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed">
                <Toolbar>
                    {/* <Menu style={{ width: "8vw" }} /> */}

                    <ExpandableMenu scrollToRef={props.scrollToRef} />
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1, textAlign: "center" }}
                        // style={{ color: "#7fcaf9" }} 
                        style={{ color: "cyan" }} 
                    >
                        SAM VARGHESE
                    </Typography>
                    <Button color="inherit" onClick={
                        () => {
                            window.scrollTo({ top: 0, behavior: "smooth" });
                        }
                    }>Home</Button>
                    {/* <Button color="inherit">Login</Button> */}
                </Toolbar>
            </AppBar>
        </Box>
    );
}
