import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { ReactComponent as Menu } from "../../images/menu_icon.svg";
import { ReactComponent as ProjectIcon } from "../../images/project.svg";
import { ReactComponent as IntroIcon } from "../../images/intro.svg";
import { ReactComponent as ExperienceIcon } from "../../images/experience.svg";
import { ReactComponent as AchievementIcon } from "../../images/achievement.svg";
import { ReactComponent as EducationIcon } from "../../images/education.svg";
import { ReactComponent as ContactIcon } from "../../images/contact.svg";
import "./expandable_menu.scss";

export default function ExpandableMenu(props) {
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };


    const DrawerList = (
        <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleDrawer(false)}
        >
            <List>
                <ListItem
                    key="JUMP TO"
                    disablePadding
                    className = "menu_item"
                >
                    <ListItemButton
                        onClick={() => {
                            window.scrollTo({ top: 0, behavior: "smooth" });
                        }}
                    >
                        {/* <ListItemIcon>
                            <IntroIcon className = "sections_icon" />
                        </ListItemIcon> */}
                        <ListItemText primary="JUMP TO" style={{textAlign: "center"}} />
                    </ListItemButton>
                </ListItem>
                <Divider />
                {/* Intro Menu Item */}
                <ListItem
                    key="Introduction"
                    disablePadding
                    className = "menu_item"
                >
                    <ListItemButton
                        onClick={() => {
                            window.scrollTo({ top: 0, behavior: "smooth" });
                        }}
                    >
                        <ListItemIcon>
                            <IntroIcon className = "sections_icon" />
                        </ListItemIcon>
                        <ListItemText primary="Introduction" />
                    </ListItemButton>
                </ListItem>
                {/* Experience Menu Item */}
                <ListItem
                    key="Experience"
                    disablePadding
                    className = "menu_item"
                >
                    <ListItemButton
                        onClick={() => {
                            if (true) {
                                console.log(
                                    props.scrollToRef.experienceRef.current
                                );
                                props.scrollToRef.experienceRef.current.scrollIntoView(
                                    {
                                        behavior: "smooth",
                                    }
                                );
                            } else {
                                console.log("Not scrolling");
                            }
                        }}
                    >
                        <ListItemIcon>
                            <ExperienceIcon className = "sections_icon" />
                        </ListItemIcon>
                        <ListItemText primary="Experience" />
                    </ListItemButton>
                </ListItem>
                {/* Achievement Menu Item */}
                <ListItem
                    key="Achievements"
                    disablePadding
                    className = "menu_item"
                >
                    <ListItemButton
                        onClick={() => {
                            if (true) {
                                console.log(
                                    props.scrollToRef.achievementRef.current
                                );
                                props.scrollToRef.achievementRef.current.scrollIntoView(
                                    {
                                        behavior: "smooth",
                                    }
                                );
                            } else {
                                console.log("Not scrolling");
                            }
                        }}
                    >
                        <ListItemIcon>
                            <AchievementIcon className = "sections_icon" />
                        </ListItemIcon>
                        <ListItemText primary="Achievements" />
                    </ListItemButton>
                </ListItem>
                {/* Education Menu Item */}
                <ListItem
                    key="Education"
                    disablePadding
                    className = "menu_item"
                >
                    <ListItemButton
                        onClick={() => {
                            if (true) {
                                console.log(
                                    props.scrollToRef.educationRef.current
                                );
                                props.scrollToRef.educationRef.current.scrollIntoView(
                                    {
                                        behavior: "smooth",
                                    }
                                );
                            } else {
                                console.log("Not scrolling");
                            }
                        }}
                    >
                        <ListItemIcon>
                            <EducationIcon className = "sections_icon" />
                        </ListItemIcon>
                        <ListItemText primary="Education" />
                    </ListItemButton>
                </ListItem>
                {/* Project Menu Item */}
                <ListItem
                    key="Project"
                    disablePadding
                    className = "menu_item"
                >
                    <ListItemButton
                        onClick={() => {
                            if (true) {
                                console.log(
                                    props.scrollToRef.projectRef.current
                                );
                                props.scrollToRef.projectRef.current.scrollIntoView(
                                    {
                                        behavior: "smooth",
                                    }
                                );
                            } else {
                                console.log("Not scrolling");
                            }
                        }}
                    >
                        <ListItemIcon>
                            <ProjectIcon className = "sections_icon" />
                        </ListItemIcon>
                        <ListItemText primary="Project" />
                    </ListItemButton>
                </ListItem>
                {/* Contact Menu Item */}
                <ListItem
                    key="Contact"
                    disablePadding
                    className = "menu_item"
                >
                    <ListItemButton
                        onClick={() => {
                            if (true) {
                                console.log(
                                    props.scrollToRef.contactRef.current
                                );
                                props.scrollToRef.contactRef.current.scrollIntoView(
                                    {
                                        behavior: "smooth",
                                    }
                                );
                            } else {
                                console.log("Not scrolling");
                            }
                        }}
                    >
                        <ListItemIcon>
                            <ContactIcon className = "sections_icon" />
                        </ListItemIcon>
                        <ListItemText primary="Contact" />
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    );

    return (
        <div>
            <Button onClick={toggleDrawer(true)}>
                <Menu class="menu_icon" />
            </Button>
            <Drawer open={open} onClose={toggleDrawer(false)}>
                {DrawerList}
            </Drawer>
        </div>
    );
}
