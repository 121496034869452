import "./fav_quote.scss";

export default function FavouriteQuote() {
    return (
        <div className="quoteOuterDiv">
            <span className="informer">
                Favourite quote:
                <br />
            </span>
            <hr />
            <p className="quote">
                "Don't judge each day by the harvest you reap but by the seeds
                that you plant." - Robert Louis
            </p>
        </div>
    );
}