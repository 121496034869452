import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
// import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
// import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views-react-18-fix";
import { autoPlay } from "react-swipeable-views-utils-react-18-fix";
import sih from "../../images/prize.jpg";
import kavach from "../../images/kavach.jpg";
import kavach1 from "../../images/kavach1.jpg";
import mayor_office from "../../images/mayor_office.jpg";
import kpmg_img from "../../images/DP4KPMG.jpg";
import moe_interview from "../../images/MoE_Interview.png";
import "./personal_carousel.scss";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
    {
        label: "Interviewed by Ministry of Education",
        imgPath:
            moe_interview,
    },
    {
        label: "Intern, KPMG Mumbai",
        imgPath:
            kpmg_img,
    },
    {
        label: "Leader, Winning Team, SIH 2023",
        imgPath:
            sih,
    },
    {
        label: "Awarded by Supt. Maharashtra Police",
        imgPath:
            kavach,
    },
    {
        label: "36 hrs Hackathon",
        imgPath:
            kavach1,
    },
    {
        label: "Invited to Mayor's Office, Indore",
        imgPath:
            mayor_office,
    },
];

function PersonalCarousel() {
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = images.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    return (
        <Box className="carousel_box">
            <Paper
                square
                elevation={0}
                sx={{
                    pl: 2,
                    bgcolor: "background.default",
                }}
                className="carousel_paper"
            >
                <Typography className="image_desc">
                    <i>{images[activeStep].label}</i>
                </Typography>
            </Paper>
            <AutoPlaySwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
                className="swipeable_view"
            >
                {images.map((step, index) => (
                    <div key={step.label}>
                        {Math.abs(activeStep - index) <= 2 ? (
                            <Box
                                component="img"
                                src={step.imgPath}
                                alt={step.label} 
                                className= "swipeable_images"
                            />
                        ) : null}
                    </div>
                ))}
            </AutoPlaySwipeableViews>
            <MobileStepper
                steps={maxSteps}
                position="static"
                activeStep={activeStep} 
                className="bottom_bar"
                nextButton={
                    <Button
                        size="small"
                        onClick={handleNext}
                        disabled={activeStep === maxSteps - 1}
                    >
                        Next
                        {/* {theme.direction === "rtl" ? (
                            <KeyboardArrowLeft />
                        ) : (
                            <KeyboardArrowRight />
                        )} */}
                    </Button>
                }
                backButton={
                    <Button
                        size="small"
                        onClick={handleBack}
                        disabled={activeStep === 0}
                    >
                        {/* {theme.direction === "rtl" ? (
                            <KeyboardArrowRight />
                        ) : (
                            <KeyboardArrowLeft />
                        )} */}
                        Back
                    </Button>
                }
            />
        </Box>
    );
}

export default PersonalCarousel;
