import { ReactComponent as MailIcon } from "../../images/mail.svg";
import { ReactComponent as InstagramIcon } from "../../images/instagram.svg";
import { ReactComponent as LinkedinIcon } from "../../images/linkedin.svg";
import React, { forwardRef } from "react";
import "./contact.scss";

const Contact = forwardRef((props, ref) => {
    return (
        <div className="contact_outer_div" ref={ref}>
            <h1>Contact</h1>
            <div style={{ width: "70%" }}>
                <div
                    className="contact_div"
                    onClick={() => {
                        window.open("mailto:sam.varghese08@nmims.in");
                    }}
                >
                    <MailIcon className="contact_icon" />
                    <span>
                        {/* <a href = "mailto:sam.varghese08@nmims.in">sam.varghese08@nmims.in</a> */}
                        sam.varghese08@nmims.in
                    </span>
                </div>
                <div
                    className="contact_div"
                    onClick={() => {
                        window.open(
                            "https://linkedin.openinapp.co/p0yl3",
                            "_blank"
                        );
                    }}
                >
                    <LinkedinIcon className="contact_icon" />
                    <span>sam-varghese2003</span>
                </div>
                <div
                    className="contact_div"
                    onClick={() => {
                        window.open(
                            "https://www.instagram.com/sam_varghese2003?utm_source=qr&igsh=NnFsOWJhZWVsOGNw",
                            "_blank"
                        );
                    }}
                >
                    <InstagramIcon className="contact_icon" />
                    <span>sam_varghese2003</span>
                </div>
            </div>
        </div>
    );
});
export default Contact;
