import { useEffect, useState, useRef } from "react";

const useIntersectionObserver = (options, delay) => {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);
    let timer;

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                timer = setTimeout(() => {
                    setIsVisible(true);
                }, delay);
            } else {
                clearTimeout(timer);
                setIsVisible(false);
            }
        }, options);

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
            clearTimeout(timer);
        };
    }, [options, delay]);

    return [ref, isVisible];
};

export default useIntersectionObserver;
