import ExperienceComponent from "./experience_component";
import React, { forwardRef } from "react";
import "./experience.scss";

var experience_data = [
    {
        company: "KPMG, India",
        role: "Industry Intelligence Intern | Mumbai",
        imagePath: require("../../images/kpmg_office.jpg"),
        description:
            "KPMG, a 'Big Four' company is among top 10 in Forbes for best places to work at, and is ranked at top 5 according to Bloomberg.",
        link: "https://www.wikiwand.com/en/KPMG",
    },
];

const Experience = forwardRef((props, ref) => {
    return (
        <div
            className="experience_section"
            ref={ref}
        >
            <h1>Experience</h1>
            {experience_data.map((element, index) => (
                <ExperienceComponent
                    imagePath={element.imagePath}
                    company={element.company}
                    role={element.role}
                    description={element.description}
                    link={element.link}
                />
            ))}
        </div>
    );
});

export default Experience;
