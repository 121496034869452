import EducationComponent from "./education_component";
import React, { forwardRef } from "react";
import "./education.scss"
var education_data = [
    {
        institution: "NMIMS",
        education_sub_heading: "MBA, BTech (Computer Engineering) | Mumbai",
        imagePath: require("../../images/nmims.jpg"),
        description:
            "NMIMS holds an NIRF rank of 21, making it equally competitive to some of the prominent IIM's of the country.",
        link: "https://www.wikiwand.com/en/SVKM%27s_NMIMS",
    },
];

const Education = forwardRef((props, ref) => {
    return (
        <div
            ref={ ref}
            className="education_section"
        >
            <h1>Education</h1>
            {education_data.map((element, index) => (
                <EducationComponent
                    imagePath={element.imagePath}
                    institution={element.institution}
                    education_sub_heading={element.education_sub_heading}
                    description={element.description}
                    link={element.link}
                />
            ))}
        </div>
    );
})
export default Education;