import AchievementComponent from "./achievement_component";
import React, { forwardRef } from "react";
import "./achievement.scss";

var achievements_data = [
    {
        title: "Smart India Hackathon'23",
        title_sub_heading: "National Winner | Tamil Nadu",
        imagePath: require("../../images/prize.jpg"),
        description:
            "Led the first winning team at SIH from University. Solved the issue of parking crisis technologically. Awarded with 1 Lakh INR.",
        link: "https://drive.google.com/file/d/15_JL_ylzJXEZnKbGst-_XKyTcGglnu7T/view?usp=sharing",
        rank: "gold",
        buttonText: "News",
    },
    {
        title: "4.5 Lakh Grant Recipient",
        title_sub_heading: "Yukti Challenge, AICTE | New Delhi",
        imagePath: require("../../images/aicte.jpg"),
        description:
            "Won the National Challenge among 36,000+ teams consisting of SIH Winners and selected startup teams.",
        link: "https://www.wikiwand.com/en/KPMG",
        rank: "gold",
        buttonText: "More",
    },
    {
        title: "Kavach Hackathon",
        title_sub_heading: "Grand Finalist | Mumbai",
        imagePath: require("../../images/kavach.jpg"),
        description:
            "Awarded by Superintendent of Mahrashtra Police, and the Director of AICTE. Offered an internship at Mahrashtra Police Cyber Cell.",
        link: "https://www.wikiwand.com/en/KPMG",
        rank: "silver",
        buttonText: "More",
    },
    {
        title: "Interviewed by MoE",
        title_sub_heading: "Ministry of Education | New Delhi",
        imagePath: require("../../images/MoE_Interview.png"),
        description:
            "Interviewed by the Ministry of Education, Innovation Cell regarding the winning project at Smart India Hackathon'23",
        link: "https://youtu.be/jZPjKlnnCqI?si=1qz0Yl6w3Uk94ndr",
        rank: "bronze",
        buttonText: "Interview",
    },
];

const Achievements = forwardRef((props, ref) => {
    return (
        <div 
            ref={ ref}
            className="achievement_section"
        >
            <h1>Highlights</h1>
            {achievements_data.map((element, index) => (
                <AchievementComponent
                    imagePath={element.imagePath}
                    title={element.title}
                    title_sub_heading={element.title_sub_heading}
                    description={element.description}
                    link={element.link}
                    rank={element.rank}
                    buttonText={ element.buttonText}
                />
            ))}
        </div>
    );
}
)

export default Achievements;