import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions } from "@mui/material";
import useIntersectionObserver from "../userIntersectionObserver";
import "./experience_component.scss";

var everVisible = false;
export default function ExperienceComponent(props) {
    const [ref, isVisible] = useIntersectionObserver(
        { threshold: 0.1 }, // Adjust the threshold as needed
        500 // Set the delay in milliseconds (e.g., 2000ms = 2 seconds)
    );
    if (isVisible) {
        everVisible = isVisible;
    }
    return (
        <div ref={ref}>
            <Card
                className="experience_card"
                style={{
                    opacity: !everVisible ? "0" : "1",
                    transition: "all 0.5s",
                    visibility: !everVisible ? "hidden" : "visible",
                }}
            >
                <CardActionArea>
                    <CardMedia
                        component="img"
                        image={props.imagePath}
                        alt="green iguana"
                        className="card_media"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            {props.company}
                        </Typography>
                        <Typography
                            gutterBottom
                            variant="body2"
                            component="div"
                            color="#dce0e3"
                            fontWeight="bold"
                        >
                            {props.role}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {props.description}
                        </Typography>
                    </CardContent>
                </CardActionArea>
                <CardActions>
                    <Button
                        size="small"
                        color="primary"
                        onClick={() => window.open(props.link, "_blank")}
                    >
                        More
                    </Button>
                </CardActions>
            </Card>
        </div>
    );
}
