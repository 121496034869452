import ReactDOM from 'react-dom/client';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import PersonalCarousel from './components/personal_carousel/personal_carousel';
import PersonalDescription from './components/personal_desc/personal_desc';
import MainAppBar from './components/appBar/appBar';
import Experience from './components/experience/experience';
import Achievements from './components/achievements/achievement';
import Education from './components/education/education';
import Projects from './components/projects/project';
import Contact from './components/contact/contact';
import Recommendations from './components/recommendations/recommendation';
import FavouriteQuote from './components/fav_quote/fav_quote';
import React, { useRef } from "react";
const darkTheme = createTheme({
    palette: {
        mode: "dark",
    },
});
const App = () => {
    const personalCarouselRef = useRef({current: null});
    const experienceRef = useRef({current: null});
    const achievementRef = useRef({current: null});
    const educationRef = useRef({current: null});
    const projectRef = useRef({current: null});
    const contactRef = useRef({ current: null });
  
  const refs = {
    personalCarouselRef,
    experienceRef,
    achievementRef,
    educationRef,
    projectRef,
    contactRef

  }

    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />
        <MainAppBar scrollToRef={ refs} />
        <PersonalCarousel ref={ personalCarouselRef} />
            <PersonalDescription />
        <Experience ref={ experienceRef} />
        <Achievements ref={ achievementRef} />
        <Education ref={ educationRef} />
            <Recommendations />
            <Projects ref={projectRef} />
            <Contact ref={contactRef} />
            <FavouriteQuote />
        </ThemeProvider>
    );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
